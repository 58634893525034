@import "../node_modules/bootstrap/scss/mixins/breakpoints";
.navbar {
  height: auto;
  padding: 0 0.5rem;
  background-color: #ffffff;
  box-shadow: 0 1px 1px #dddddd;

  .breadcrumb {
    height: $header-height;
    padding: 0;
    line-height: $header-height;
    background: none;
    flex-wrap: nowrap;

    @include media-breakpoint-up(xl) {
      .breadcrumb-item:not(:last-child) {
        margin-right: 1.5rem;
        &::after {
          content: "›";
          font-size: 2em;
          line-height: $header-height;
          left: 100%;
          top: 0;
          position: absolute;
          padding-left: 0.5rem
        }
      }
    }
    .breadcrumb-item + .breadcrumb-item {padding-left: 0;
      &::before {content: ""; display: none;}
    }
    .breadcrumb-item { position: relative; margin-right: 0.3rem;
    display: inline-block;
    }

    .dropdown-menu {
      max-height: 80vh;
      overflow-y: auto;
      border-radius: 0;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
      overflow-x: hidden;
    }
    .dropdown-item {
      font-size: 14px;
      line-height: 2rem;
      display: flex;
      flex-flow:row nowrap;
      justify-content: space-between;
    }

    .btn {
      line-height: 1;
      font-family: "Avenir Next W00", $font-family-sans-serif;
    }
    li > .btn {
      padding: 0.5rem 1rem;
      @include media-breakpoint-down(lg){
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        font-size: 0.9rem;
      }
    }
    .dropdown-toggle-split {
      padding-right: .75rem;
      padding-left: .75rem;
    }

    .filter-button {
      transition: opacity 50ms ease-in-out 10ms;
      opacity: 0.2;
      //box-shadow: none;
      &:hover{
        transition: opacity 80ms ease-in-out 0ms;
        opacity: 0.7;
      }
      &:active, &.active {
        opacity: 1 !important;
      }
    }
    .related-active {
      text-decoration: underline;
      text-decoration-color: #909090;
    }

  }
  .navbar-toggler {
    font-size: 1rem;
    margin: 0.5rem 0;
  }
  .navbar-collapse.show, .navbar-collapse.collapsing {
    background: #ffffff;
    .breadcrumb {
      height: auto;
    }
  }

  .logo {
    margin-right: 0.5rem;
    padding-top: 0;
    padding-bottom: 0;
    line-height: $header-height - 0.25rem;
    font-size: 14px; // interestingly the image becomes blurry in chrome without this
    > img {
      max-height: $header-height;
      max-width: 15rem;
      padding: 0.3rem 0;
      @include media-breakpoint-down(md) {
        & {
          max-width: 12rem;
        }
      }
    }
  }
  .titles {
    padding: 0 ;
    margin:0 0.25rem;
    height: $header-height;
    line-height: $header-height;
    .title {
      white-space: nowrap;
      font-weight: normal;
      font-size: 1rem;
      color: #424242;
    }

    @include media-breakpoint-up(lg) {
      & {
        margin: 0 0.25rem;
        .title {
          font-size: 1.4rem;
        }
      }
    }
  }


  // fix to keep long ATP titles from shoving the logo off the right side of the navbar
  #atpsDropdownButton > span {
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
  }
  @include media-breakpoint-up(md){
      #atpsDropdownButton > span {
        max-width: calc(100vw - 211px - 29rem); //211px for logo width + REM-based space for all the other margins / text elements in the navbar.
      }
  }

  @include media-breakpoint-up(lg){
    padding: 0 1rem;
    #atpsDropdownButton > span {
      max-width: calc(100vw - 211px - 33.5rem);  //211px for logo width + REM-based space for all the other margins / text elements in the navbar.
    }
  }
}
