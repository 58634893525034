$or-color: #bebebe;
.vr-or {
  flex: 0 0 0;
  width: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin-left: -1rem;
  margin-right: -1rem;
  position: relative;

  &::before{
    content: "";
    display:block;
    border-left: 2px solid $or-color;
    position: absolute;
    top: 1rem;
    bottom: 1rem;
  }
  span {
   display: block;
    line-height: 1.5rem;
    font-size: 1.2rem;
    color: $or-color;
    background: white;
    position: absolute;
    width: fit-content;
    left: 0.25rem;
    padding: 0.3rem 0;

  }
}

.hr-or{
  flex: 1 0 100%;
  display: flex;
  flex-flow:  row nowrap;
  flex-flow: row nowrap;
  border-top: 2px solid $or-color;
  max-width: calc(100% - 2rem);
  margin: 1rem;

  span {
    line-height: 1.5rem;
    font-size: 1.2rem;
    color: $or-color;
    flex: 0 0 1px;
    padding: 0 0.5rem;
    background: white;
    display: block;
    margin-top: -1rem;
    margin-left: auto;
    margin-right: auto;
  }

}

@import "triangle_spinner";
