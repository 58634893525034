.triangle-spinner, .triangle-spinner > span {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.triangle-spinner {
  display: block;
  font-size: 0;
  width: 1.3rem;
  height: 1.3rem;

  & > span {
    display: inline-block;
    float: none;
    background-color: currentColor;
    border: 0 solid currentColor;
    position: absolute;
    top: 0;
    left: 0;
    width: 30%;
    height: 30%;
    clip-path: circle(28%);
    border-radius: 100%;
  }
}

.triangle-spinner > span:nth-child(1) {
  -webkit-animation: ball-triangle-path-ball-one 2s 0s ease-in-out infinite;
  -moz-animation: ball-triangle-path-ball-one 2s 0s ease-in-out infinite;
  -o-animation: ball-triangle-path-ball-one 2s 0s ease-in-out infinite;
  animation: ball-triangle-path-ball-one 2s 0s ease-in-out infinite;
}
.triangle-spinner > span:nth-child(2) {
  -webkit-animation: ball-triangle-path-ball-two 2s 0s ease-in-out infinite;
  -moz-animation: ball-triangle-path-ball-two 2s 0s ease-in-out infinite;
  -o-animation: ball-triangle-path-ball-two 2s 0s ease-in-out infinite;
  animation: ball-triangle-path-ball-two 2s 0s ease-in-out infinite;
}
.triangle-spinner > span:nth-child(3) {
  -webkit-animation: ball-triangle-path-ball-tree 2s 0s ease-in-out infinite;
  -moz-animation: ball-triangle-path-ball-tree 2s 0s ease-in-out infinite;
  -o-animation: ball-triangle-path-ball-tree 2s 0s ease-in-out infinite;
  animation: ball-triangle-path-ball-tree 2s 0s ease-in-out infinite;
}

/*
 * Animations
 */
@-webkit-keyframes ball-triangle-path-ball-one {
  0% {
    -webkit-transform: translate(0, 220%);
    transform: translate(0, 220%);
  }
  17% {
    opacity: 1;
  }
  33% {
    opacity: 1;
    -webkit-transform: translate(110%, 0);
    transform: translate(110%, 0);
  }
  50% {
    opacity: 1;
  }
  66% {
    opacity: 1;
    -webkit-transform: translate(220%, 220%);
    transform: translate(220%, 220%);
  }
  83% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 220%);
    transform: translate(0, 220%);
  }
}
@-moz-keyframes ball-triangle-path-ball-one {
  0% {
    -moz-transform: translate(0, 220%);
    transform: translate(0, 220%);
  }
  17% {
    opacity: 1;
  }
  33% {
    opacity: 1;
    -moz-transform: translate(110%, 0);
    transform: translate(110%, 0);
  }
  50% {
    opacity: 1;
  }
  66% {
    opacity: 1;
    -moz-transform: translate(220%, 220%);
    transform: translate(220%, 220%);
  }
  83% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    -moz-transform: translate(0, 220%);
    transform: translate(0, 220%);
  }
}
@-o-keyframes ball-triangle-path-ball-one {
  0% {
    -o-transform: translate(0, 220%);
    transform: translate(0, 220%);
  }
  17% {
    opacity: 1;
  }
  33% {
    opacity: 1;
    -o-transform: translate(110%, 0);
    transform: translate(110%, 0);
  }
  50% {
    opacity: 1;
  }
  66% {
    opacity: 1;
    -o-transform: translate(220%, 220%);
    transform: translate(220%, 220%);
  }
  83% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    -o-transform: translate(0, 220%);
    transform: translate(0, 220%);
  }
}
@keyframes ball-triangle-path-ball-one {
  0% {
    -webkit-transform: translate(0, 220%);
    -moz-transform: translate(0, 220%);
    -o-transform: translate(0, 220%);
    transform: translate(0, 220%);
  }
  17% {
    opacity: 1;
  }
  33% {
    opacity: 1;
    -webkit-transform: translate(110%, 0);
    -moz-transform: translate(110%, 0);
    -o-transform: translate(110%, 0);
    transform: translate(110%, 0);
  }
  50% {
    opacity: 1;
  }
  66% {
    opacity: 1;
    -webkit-transform: translate(220%, 220%);
    -moz-transform: translate(220%, 220%);
    -o-transform: translate(220%, 220%);
    transform: translate(220%, 220%);
  }
  83% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 220%);
    -moz-transform: translate(0, 220%);
    -o-transform: translate(0, 220%);
    transform: translate(0, 220%);
  }
}
@-webkit-keyframes ball-triangle-path-ball-two {
  0% {
    -webkit-transform: translate(110%, 0);
    transform: translate(110%, 0);
  }
  17% {
    opacity: 1;
  }
  33% {
    opacity: 1;
    -webkit-transform: translate(220%, 220%);
    transform: translate(220%, 220%);
  }
  50% {
    opacity: 1;
  }
  66% {
    opacity: 1;
    -webkit-transform: translate(0, 220%);
    transform: translate(0, 220%);
  }
  83% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(110%, 0);
    transform: translate(110%, 0);
  }
}
@-moz-keyframes ball-triangle-path-ball-two {
  0% {
    -moz-transform: translate(110%, 0);
    transform: translate(110%, 0);
  }
  17% {
    opacity: 1;
  }
  33% {
    opacity: 1;
    -moz-transform: translate(220%, 220%);
    transform: translate(220%, 220%);
  }
  50% {
    opacity: 1;
  }
  66% {
    opacity: 1;
    -moz-transform: translate(0, 220%);
    transform: translate(0, 220%);
  }
  83% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    -moz-transform: translate(110%, 0);
    transform: translate(110%, 0);
  }
}
@-o-keyframes ball-triangle-path-ball-two {
  0% {
    -o-transform: translate(110%, 0);
    transform: translate(110%, 0);
  }
  17% {
    opacity: 1;
  }
  33% {
    opacity: 1;
    -o-transform: translate(220%, 220%);
    transform: translate(220%, 220%);
  }
  50% {
    opacity: 1;
  }
  66% {
    opacity: 1;
    -o-transform: translate(0, 220%);
    transform: translate(0, 220%);
  }
  83% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    -o-transform: translate(110%, 0);
    transform: translate(110%, 0);
  }
}
@keyframes ball-triangle-path-ball-two {
  0% {
    -webkit-transform: translate(110%, 0);
    -moz-transform: translate(110%, 0);
    -o-transform: translate(110%, 0);
    transform: translate(110%, 0);
  }
  17% {
    opacity: 1;
  }
  33% {
    opacity: 1;
    -webkit-transform: translate(220%, 220%);
    -moz-transform: translate(220%, 220%);
    -o-transform: translate(220%, 220%);
    transform: translate(220%, 220%);
  }
  50% {
    opacity: 1;
  }
  66% {
    opacity: 1;
    -webkit-transform: translate(0, 220%);
    -moz-transform: translate(0, 220%);
    -o-transform: translate(0, 220%);
    transform: translate(0, 220%);
  }
  83% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(110%, 0);
    -moz-transform: translate(110%, 0);
    -o-transform: translate(110%, 0);
    transform: translate(110%, 0);
  }
}
@-webkit-keyframes ball-triangle-path-ball-tree {
  0% {
    -webkit-transform: translate(220%, 220%);
    transform: translate(220%, 220%);
  }
  17% {
    opacity: 1;
  }
  33% {
    opacity: 1;
    -webkit-transform: translate(0, 220%);
    transform: translate(0, 220%);
  }
  50% {
    opacity: 1;
  }
  66% {
    opacity: 1;
    -webkit-transform: translate(110%, 0);
    transform: translate(110%, 0);
  }
  83% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(220%, 220%);
    transform: translate(220%, 220%);
  }
}
@-moz-keyframes ball-triangle-path-ball-tree {
  0% {
    -moz-transform: translate(220%, 220%);
    transform: translate(220%, 220%);
  }
  17% {
    opacity: 1;
  }
  33% {
    opacity: 1;
    -moz-transform: translate(0, 220%);
    transform: translate(0, 220%);
  }
  50% {
    opacity: 1;
  }
  66% {
    opacity: 1;
    -moz-transform: translate(110%, 0);
    transform: translate(110%, 0);
  }
  83% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    -moz-transform: translate(220%, 220%);
    transform: translate(220%, 220%);
  }
}
@-o-keyframes ball-triangle-path-ball-tree {
  0% {
    -o-transform: translate(220%, 220%);
    transform: translate(220%, 220%);
  }
  17% {
    opacity: 1;
  }
  33% {
    opacity: 1;
    -o-transform: translate(0, 220%);
    transform: translate(0, 220%);
  }
  50% {
    opacity: 1;
  }
  66% {
    opacity: 1;
    -o-transform: translate(110%, 0);
    transform: translate(110%, 0);
  }
  83% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    -o-transform: translate(220%, 220%);
    transform: translate(220%, 220%);
  }
}
@keyframes ball-triangle-path-ball-tree {
  0% {
    -webkit-transform: translate(220%, 220%);
    -moz-transform: translate(220%, 220%);
    -o-transform: translate(220%, 220%);
    transform: translate(220%, 220%);
  }
  17% {
    opacity: 1;
  }
  33% {
    opacity: 1;
    -webkit-transform: translate(0, 220%);
    -moz-transform: translate(0, 220%);
    -o-transform: translate(0, 220%);
    transform: translate(0, 220%);
  }
  50% {
    opacity: 1;
  }
  66% {
    opacity: 1;
    -webkit-transform: translate(110%, 0);
    -moz-transform: translate(110%, 0);
    -o-transform: translate(110%, 0);
    transform: translate(110%, 0);
  }
  83% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(220%, 220%);
    -moz-transform: translate(220%, 220%);
    -o-transform: translate(220%, 220%);
    transform: translate(220%, 220%);
  }
}
