//start bootstrap customization
@import "../node_modules/bootstrap/scss/functions";
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&display=swap');

$white: #ffffff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000000 !default;
$gray-dark: rgb(27, 56, 66);

$blue: #0275d8 !default;
$indigo: #7c46f2 !default;
$purple: #7527c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #50BE97 !default;
$teal: #3ec9c4 !default;
$cyan: #1cb8b0 !default;

$colors: () !default;
$colors: map-merge((
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    //"teal": $teal,
    //"cyan": $cyan,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": rgb(27, 56, 66)
), $colors);

$variable-colors: (
    "asset": #ffda1d,
    "exposed": #495a6b,
    "ex": #495a6b,
    "potential_impact": #7f6000,
    "pi": #7f6000,
    "adaptive_capacity": #274e13,
    "ac": #274e13,
    "vulnerability": #6e2f26,
    "v": #6e2f26,
    "risk_probability": #1c4587,
    "rp": #1c4587,
    "risk_consequence": #741b47,
    "rc": #741b47,
    "risk": #6d608c,
    "r": #6d608c,
    "combined_vulnerability_risk": #cf202a,
    "vr": #cf202a,
    'population': #3e007d,
    'households': #d94701,
    'economic': #1F8459
);

$secondary-color-hover: #054ebb;
$disabled-color: #9f9999;
$disabled-light-color: #cccaca;
$warning-color: #eeb216;
$error-color: #e74949;

$primary: $green !default;
$secondary: #9BB5B5; // thanks to the cute lil birb from r/eyebleach "Feels so good"
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

$theme-colors: (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
) !default;

$text-muted:$gray-600 !default;

$color-contrast-light: $white;

$header-height: 3rem;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
);
$grid-gutter-width: 1.5rem !default;
$spacer: 1rem;
$paragraph-margin-bottom: 1.5rem !default;
$spacers: (
    -3: -($spacer),
    -2: -($spacer * .5),
    -1: -($spacer * .25),
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3),
    6: ($spacer * 3.5),
    7: ($spacer * 4),
    8: ($spacer * 4.5),
    9: ($spacer * 5),
);

$sizes: (
    20: 20%,
    25: 25%,
    40: 40%,
    50: 50%,
    60: 60%,
    75: 75%,
    80: 80%,
    100: 100%,
    auto: auto
);

@each $size, $length in $sizes {
  .flex-#{$size} {
    flex-basis: $length !important;
  }
}

@each $name, $color in map_merge($colors, $variable-colors) {
  .bt-#{$name} {
    border-top: rgba($color, 0.7) solid 0.15rem !important;
  }

  .bl-#{$name} {
    border-left: rgba($color, 0.7) solid 0.15rem !important;
  }
}

@each $variable, $color in $variable-colors {
  .btn-#{$variable} {
    background-image: linear-gradient(lighten($color, 10%), $color) !important;
    background-color: $color;
    color: white !important;
    border-color: lighten($color, 15%);

    &:not(.disabled):hover {
      background-image: linear-gradient(lighten($color, 10%), darken($color, 10%)) !important;
      background-color: $color;
    }

    &.disabled {
      background-color: $color;
      background-image: none !important;
    }

    &.active {
      border: 2px solid darken($color, 15%);
    }
  }
}


$variable-bg-colors: (
    'population': #dbdaea,
    'households': #feedde,
    'economic': #f2f7f0
);
@each $variable, $color in $variable-bg-colors {
  .bg-#{$variable} {
    background-color: $color !important;
  }
}

$input-btn-focus-width:         .16rem !default;

$border-radius: 0rem !default;
$border-radius-lg: 0rem !default;
$border-radius-sm: 0rem !default;

$box-shadow-sm: 0 .125rem .25rem rgba($black, .075) !default;
$box-shadow: 0 .5rem 1rem rgba($black, .15) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, .175) !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: ($font-size-base * 1.25) !default;
$font-size-sm: ($font-size-base * .875) !default;

$font-family-sans-serif: "Fira Sans", "Segoe UI", Arial, -apple-system sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;
$line-height-base: 1.65 !default;
$card-spacer-y: 0.25rem;
$card-spacer-x: 0.6rem;
$card-group-margin: $card-spacer-y !default;
$card-deck-margin: $card-group-margin !default;
$card-columns-count: 3 !default;
$card-columns-gap: $card-spacer-y * 1.8 !default;
$card-columns-margin: $card-spacer-y !default;
$card-img-overlay-padding: 0.6rem;


@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/bootstrap";


@import "node_modules/acceladapt-online-lib/scss/auth";
@import "header";

@include media-breakpoint-down(md) {
  .d-none-md {
    display: none !important;
  }

  .d-flex-md {
    display: flex !important;
  }

  .d-block-md {
    display: block !important;
  }

  .w-100-md {
    width: 100% !important;
  }

  .w-75-md {
    width: 75% !important;
  }

  .w-50-md {
    width: 50% !important;
  }

  .w-25-md {
    width: 25% !important;
  }

  // margin

  .mb-md-down-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-down-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-down-3 {
    margin-bottom: 0.75rem !important;
  }

  .mt-md-down-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-down-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-down-3 {
    margin-top: 0.75rem !important;
  }

  .ms-md-down-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-down-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-down-3 {
    margin-left: 0.75rem !important;
  }

  .me-md-down-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-down-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-down-3 {
    margin-right: 0.75rem !important;
  }

  // Padding

  .pt-md-down-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-down-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-down-3 {
    padding-top: 0.75rem !important;
  }

  .pb-md-down-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-down-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-down-3 {
    padding-bottom: 0.75rem !important;
  }

  .left-border-md {
    border-left: 1px solid $gray-400;
  }

  .top-border-md {
    border-top: 1px solid $gray-400;
  }
}

@include media-breakpoint-down(lg) {
  .d-none-lg {
    display: none !important;
  }

  // margin

  .mb-lg-down-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-down-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-down-3 {
    margin-bottom: 0.75rem !important;
  }

  .mt-lg-down-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-down-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-down-3 {
    margin-top: 0.75rem !important;
  }

  .ms-lg-down-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-down-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-down-3 {
    margin-left: 0.75rem !important;
  }

  .me-lg-down-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-down-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-down-3 {
    margin-right: 0.75rem !important;
  }

  // Padding

  .pt-lg-down-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-down-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-down-3 {
    padding-top: 0.75rem !important;
  }

  .pb-lg-down-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-down-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-down-3 {
    padding-bottom: 0.75rem !important;
  }

  .top-border-lg-down {
    border-top: 1px solid $gray-400;
  }
}

@include media-breakpoint-down(xl) {
  .d-none-xl {
    display: none !important;
  }
}

@include media-breakpoint-up(md) {
  .d-none-md-up {
    display: none !important;
  }

  .d-flex-md-up {
    display: flex !important;
  }

  .d-block-md-up {
    display: block !important;
  }

  .w-100-md-up {
    width: 100% !important;
  }

  .w-75-md-up {
    width: 75% !important;
  }

  .w-50-md-up {
    width: 50% !important;
  }

  .w-25-md-up {
    width: 25% !important;
  }

  // margin

  .mb-md-up-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-up-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-up-3 {
    margin-bottom: 0.75rem !important;
  }

  .mt-md-up-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-up-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-up-3 {
    margin-top: 0.75rem !important;
  }

  .ms-md-up-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-up-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-up-3 {
    margin-left: 0.75rem !important;
  }

  .me-md-up-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-up-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-up-3 {
    margin-right: 0.75rem !important;
  }

  // Padding

  .pt-md-up-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-up-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-up-3 {
    padding-top: 0.75rem !important;
  }

  .pb-md-up-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-up-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-up-3 {
    padding-bottom: 0.75rem !important;
  }

  .left-border-md-up {
    border-left: 1px solid $gray-400;
  }

}

@include media-breakpoint-up(lg) {
  .d-none-lg-up {
    display: none !important;
  }

  .d-flex-lg-up {
    display: flex !important;
  }

  .d-block-lg-up {
    display: block !important;
  }

  .w-100-lg-up {
    width: 100% !important;
  }

  .w-75-lg-up {
    width: 75% !important;
  }

  .w-50-lg-up {
    width: 50% !important;
  }

  .w-25-lg-up {
    width: 25% !important;
  }

  // margin

  .mb-lg-up-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-up-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-up-3 {
    margin-bottom: 0.75rem !important;
  }

  .mt-lg-up-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-up-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-up-3 {
    margin-top: 0.75rem !important;
  }

  .ms-lg-up-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-up-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-up-3 {
    margin-left: 0.75rem !important;
  }

  .me-lg-up-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-up-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-up-3 {
    margin-right: 0.75rem !important;
  }

  // Padding

  .pt-lg-up-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-up-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-up-3 {
    padding-top: 0.75rem !important;
  }

  .pb-lg-up-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-up-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-up-3 {
    padding-bottom: 0.75rem !important;
  }

  .ps-lg-up-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-up-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-up-3 {
    padding-left: 0.75rem !important;
  }

  .pe-lg-up-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-up-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-up-3 {
    padding-right: 0.75rem !important;
  }

  .left-border-lg-up {
    border-left: 1px solid $gray-400;
  }

}

@include media-breakpoint-up(xl) {
  .d-none-xl-up {
    display: none !important;
  }
}


.btn {
  border-radius: 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.btn-light {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: 1px solid #cccccc;


  &.hover, &:hover {
    background-color: #e2e6ea !important;
    border: 1px solid darken(#cccccc,5%);
  }

  &.active, &:active,  {
    background-color: #dae0e5 !important;
    border: 1px solid darken(#dae0e5,5%);
  }
}
// an alternative to btn-light
.btn-muted {
  @include button-variant(
      $white,
      $white,
      $color: $text-muted,
      $hover-background: darken($light, 15%),
      $hover-border: $text-muted,
      $hover-color: darken($text-muted,15%),
      $active-background: darken($light, 5%),
      $active-border: darken($text-muted,10%),
      $active-color: darken($text-muted,10%),
      $disabled-background: $white,
      $disabled-border: $white,
      $disabled-color: lighten($text-muted, 15%)
  );
  box-shadow: none;
}

// a borderless button variant
.btn-outline-none {
  @include button-outline-variant(
      #808080,
      $color-hover: $gray-700,
      $active-background: transparent,
      $active-border: transparent,
      $active-color: darken(#808080,10%)
  );
  border: 0;
  box-shadow: none;
  &.hover, &:hover, &.active, &:active{
    text-shadow: 0 0 #495057;
  }
}

//end bootstrap customization

$fa-font-path: '/node_modules/acceladapt-online-lib/assets/fontawesome/webfonts';
@import "node_modules/acceladapt-online-lib/scss/fontawesome";


body {
  //background: url('/images/black-thread-light.png') repeat;
  font-family: $font-family-sans-serif;
  background: #f5f5f5;
  font-weight: 400;
  color: #444;
  font-size: 14px;
  overflow:auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


body > div.container {
  padding-top: $header-height;
  > * {
    margin: 0 !important;
    //min-height: calc(100vh - #{$header-height} - 4rem);
  }
}

.card {
  border: 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 0 16px 0 rgba(0, 0, 0, 0.05)
}


.cards-wrapper {
  background-color: white !important;
}

.aap-shadow {
  box-shadow: 0px 2px 10px rgba(208, 205, 205, 0.77);
}

.bg-gray {
  background-color: #fafafa !important;
}

.bg-primary {
  background-color: $primary !important;
}

#app {
  height: 100%;
}

.aap-card {
  transition: border-color 200ms ease-in;

  &:hover {
    border-color: $primary !important;
  }

}

.create-project-card {
  min-height: 13rem;
}

.btn-outline-primary-aap {
  background-color: white;
  color: $primary;

  border: 1px solid $primary;
  border-radius: 0px;

  transition: background-color 200ms ease-in !important;

  &:hover {
    background-color: $primary !important;
    color: white;
  }

}

.btn-primary {
  background-color: $primary;
  color: white;

  border: 1px solid $primary;
  border-radius: 0px;

  transition: background-color 200ms ease-in, color 200ms ease-in !important;

  &:hover {
    background-color: darken($primary, 10%) !important;
    color: white;
  }

}

.btn-secondary {
  background-color: white;
  color: $secondary;

  border: 1px solid $secondary;
  border-radius: 0px;

  transition: background-color 200ms ease-in !important;

  &:hover {
    background-color: $secondary !important;
    color: white;
  }
}

.btn-outline-error {
  background-color: white;
  color: $error-color;

  border: 1px solid $error-color;
  border-radius: 0px;

  transition: background-color 200ms ease-in, color 200ms ease-in !important;

  &:hover {
    background-color: $error-color !important;
    color: white;
  }

}

.btn-outline-dark {
  border-color: $gray-600;
  background-color: white;
  color: $gray-600;
  transition: background-color 200ms ease-in, color 200ms ease-in !important;

  &:hover {
    background-color: $gray-600;
    color: white;
  }

}

.btn-aap-link {

  color: $secondary;
  text-decoration: none;
  transition: color 200ms ease-in;

  &:hover {
    color: $primary;
    cursor: pointer;
  }

}

.project-card .project-date {
  color: $disabled-color;
}

.aap-title {
  margin: 0px;
  font-weight: bold;
}

.create-project-input {
  border: 0px;
  border-bottom: 1px solid $disabled-color;
  min-width: 12rem;
  text-align: center;
  margin: ($spacer * 0.25) ($spacer  * 0.5);
  &:focus {
    outline: none;
  }
}

.aap-input {
  border-radius: 0px;
  //padding: 0.2rem;
  outline: none;

  width: unset;

  &::placeholder {
    font-size: 0.85rem;
    color: $disabled-color;
  }
}

.flow-top {
  text-align: center;

  .flow-top-active {
    background-color: $primary;
    color: white;
  }
}

.border-active {
  border-color: $primary !important;
}

.flow-side {
  border-right: 1px solid $disabled-light-color;
}

.flow-side-active {
  color: $primary;
  font-weight: bold;
}

.module-type {
  min-height: 15rem;
  max-height: 15rem;
}

.status-ready {
  //border: 1px solid $primary;
  color: $primary;
  padding: 0.3rem;
}

.status-pending {
  //border: 1px solid $warning-color;
  color: $warning;
  padding: 0.3rem;
}

.status-error {
  //border: 1px solid $error-color;
  color: $danger;
  padding: 0.3rem;
}

/*
-webkit-background-clip 95% caniuse
-webkit-text-fill-color 95% caniuse
*/
.module-type .module-content {
  height: 60%;
  background: -webkit-linear-gradient(top,#000 70%, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.module-title {
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.file-upload {
  background-color: white !important;

  .dropzone {
    background-color: white !important;
  }

  .error {
    color: red
  }

  .upload-icon {
    background-color:#ececec;
    border-radius: 10px;

    .fa-arrow-up-from-bracket {
      color: #95a19a;
    }
  }

  #file-upload-input {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
  }
}

.loading {
  animation: loading-animation 1s linear infinite alternate;
}

.loading-text {
  width: 100%;
  height: 0.45rem;
}

.dropdown-menu {
  max-height: 25rem;
  overflow-y: auto;
  overflow-x: hidden;

  .dropdown-item {

    transition: background-color 200ms ease-in, color 200ms ease-in;

    &:hover {
      cursor: pointer;
      background-color: $green;
      color: white;
    }
  }
}

.selected-layer {

  color: $green;

  &:hover {
    cursor: pointer;
    color: $red;
  }
}

@keyframes loading-animation {
  0% {
    background-color: #e5e5e5;
  }

  100% {
    background-color: #bdbdbd;
  }

}

.delete-asset-threat {
  transition: color 200ms ease-in;

  &:hover {
    color: $danger;
    cursor: pointer;
  }
}

.success-message {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: center;
  font-weight: bold;
  color: $green;
  border: 2px solid $green;
  border-radius: 3px;

  &:hover {
    cursor: pointer;
  }
}

.error-message {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: center;
  font-weight: bold;
  color: $danger;
  border: 2px solid $danger;
  border-radius: 3px;

  &:hover {
    cursor: pointer;
  }
}

.warning-message {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: center;
  font-weight: bold;
  color: $yellow;
  border: 2px solid $yellow;
  border-radius: 3px;

  &:hover {
    cursor: pointer;
  }
}

.disable-btn {
  cursor: not-allowed !important;
}

.fail-icon {
  color: $danger;
}

.warning-icon {
  color: $warning;
}

.rotate-icon {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.dropdown-item-selected {
  background-color: $green;
  color: white !important;
}

.tag-section {
  border: 1px solid $border-color;
  background-color: rgba(155, 181, 181, 0.1);
}

.component {
  position: relative;

  .delete-component {
    position: absolute;
    right: 0;
    top: 0;
    transition: color 200ms ease-in;

    &:hover {
      color: $danger;
      cursor: pointer;
    }
  }
}

.modify-item {
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: $gray-600;

  a {
    color: $gray-600;
    text-decoration: none;
  }

  .active {
    color: $green;
    font-weight: bold;
  }

}

.active {
  color: $green;
  font-weight: bold;
}

.layer-delete {
  position: relative;
  background-color: #dedede;
  filter: opacity(0.35);

  .delete-rotate-icon {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    color: #000000;
  }
}